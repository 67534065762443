:root {
  --c-one: #e9c674;
  --c-two: #73cbbf;
  --c-three: #de8579;
  --c-four: #00d2ff;
  --dark: #282c34;
  --dark-green: #006c60;
  --light: #fafafa;
  --header-height: 80px;
  --content-size: 1400px;
  @media only screen and (max-width: 1250px) {
    --header-height: 60px;
  }

  @media only screen and (max-width: 768px) {
    --header-height: 40px;
  }
}

/* Firefox */
html {
  scrollbar-color: var(--dark-green) var(--c-one);
  scrollbar-width: thin;
}

/* WebKit and Chromiums */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--dark-green);
}

::-webkit-scrollbar-thumb {
  background: var(--c-one);
  border-radius: 4px;
}

.App {
  text-align: center;
  font-weight: 400;
  position: relative;
}
* ::selection {
  background: var(--c-two);
  color: var(--dark);
}
