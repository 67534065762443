.sun {
  position: absolute;
  top: 150px;
  left: 40%;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 0px 0px 25px 8px lightgoldenrodyellow;
  animation: sun_anim 5s linear infinite;
}

@media only screen and (max-width: 600px) {
  .sun .ray_box {
    display: none;
  }
}

.ray_box {
  position: absolute;
  margin: auto;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 70px;
  -webkit-animation: ray_anim 120s linear infinite;
  animation: ray_anim 120s linear infinite;
}
.ray {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  margin-left: 10px;
  border-radius: 80% 80% 0 0;
  position: absolute;
  opacity: 0.1;
}

.ray1 {
  height: 170px;
  width: 30px;
  transform: rotate(180deg);
  top: -175px;
  left: 15px;
}
.ray2 {
  height: 100px;
  width: 8px;
  transform: rotate(220deg);
  top: -90px;
  left: 75px;
}
.ray3 {
  height: 170px;
  width: 50px;
  transform: rotate(250deg);
  top: -80px;
  left: 100px;
}
.ray4 {
  height: 120px;
  width: 14px;
  transform: rotate(305deg);
  top: 30px;
  left: 100px;
}
.ray5 {
  height: 140px;
  width: 30px;
  transform: rotate(-15deg);
  top: 60px;
  left: 40px;
}
.ray6 {
  height: 90px;
  width: 50px;
  transform: rotate(30deg);
  top: 60px;
  left: -40px;
}
.ray7 {
  height: 180px;
  width: 10px;
  transform: rotate(70deg);
  top: -35px;
  left: -40px;
}
.ray8 {
  height: 120px;
  width: 30px;
  transform: rotate(100deg);
  top: -45px;
  left: -90px;
}
.ray9 {
  height: 80px;
  width: 10px;
  transform: rotate(120deg);
  top: -65px;
  left: -60px;
}
.ray10 {
  height: 190px;
  width: 23px;
  transform: rotate(150deg);
  top: -185px;
  left: -60px;
}

@keyframes ray_anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes sun_anim {
  0% {
    background: var(--c-one);
    opacity: 1;
  }
  33% {
    background: #fff380;
    opacity: 0.9;
  }
  66% {
    background: #ffdf00;
    opacity: 0.8;
  }
  100% {
    opacity: 1;
    background: var(--c-one);
  }
}

/* Clouds */
.clouds {
  position: absolute;
  top: 200px;
  right: 50%;
}
.cloud {
  position: relative;
  width: 140px;
  height: 40px;
  background: white;
  border-radius: 140px;
}

.cloud:before,
.cloud:after {
  content: "";
  background: white;
  position: absolute;
  width: 70px;
  height: 55px;
  top: -10px;
  left: 6px;
  border-radius: 70px;
  transform: rotate(30deg);
}

.cloud:after {
  width: 85px;
  height: 85px;
  top: -40px;
  left: auto;
  right: 10px;
}

.c1 {
  z-index: 2;
  animation: move-cloud-1 12s linear infinite;
}

.c2 {
  transform: translateX(80px);
  z-index: 0;
  animation: move-cloud-2 8s linear infinite;
}

.c2:after {
  top: -30px;
  left: auto;
  right: 20px;
}
@media (max-width: 950px) {
  .c1 {
    animation: move-cloud-1-mobile 12s linear infinite;
  }

  .c2 {
    animation: move-cloud-2-mobile 8s linear infinite;
  }
}

@keyframes move-cloud-1 {
  0% {
    transform: translateX(0);
    opacity: 0.8;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.8;
    transform: translateX(200px);
  }
  75% {
    opacity: 0.5;
  }
  100% {
    transform: translateX(0);
    opacity: 0.8;
  }
}
@keyframes move-cloud-1-mobile {
  0% {
    transform: translateX(0);
    opacity: 0.8;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.8;
    transform: translateX(100px);
  }
  75% {
    opacity: 0.5;
  }
  100% {
    transform: translateX(0);
    opacity: 0.8;
  }
}

@keyframes move-cloud-2 {
  0% {
    transform: translateX(50px);
    opacity: 0.8;
  }
  25% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.9;
    transform: translateX(10px);
  }
  75% {
    opacity: 0.7;
  }
  100% {
    transform: translateX(0);
    opacity: 0.8;
  }
}
@keyframes move-cloud-2-mobile {
  0% {
    transform: translateX(0);
    opacity: 0.8;
  }
  25% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.9;
    transform: translateX(-100px);
  }
  75% {
    opacity: 0.7;
  }
  100% {
    transform: translateX(0);
    opacity: 0.8;
  }
}

@keyframes skyChange {
  0% {
    background: deepskyblue;
  }
  32% {
    background: #00d2ff;
  }
  41% {
    background: rgb(127, 214, 255);
  }
  70% {
    background: rgb(113, 190, 216);
  }
  100% {
    background: deepskyblue;
  }
}

/* Flower pot  */
@keyframes rotate {
  0% {
    transform: rotate(-1deg) translateY(-2px);
  }
  50% {
    transform: rotate(2deg) translateY(1px);
  }
  100% {
    transform: rotate(-1deg) translateY(-2px);
  }
}

svg .st8 {
  animation: sun_anim 5s linear infinite;
}
